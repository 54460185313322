import { useChatbotContext } from '@/app/chatbot';
import { Button } from '@/design-system-components/button/button';
import { Textarea } from '@/design-system-components/text-area/text-area';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Icon } from '../icon';

interface FormType {
  chatInput: string;
}

interface ChatBotInputProps {
  isWaitingAIResponse: boolean;
  onNewMessage: (message: string) => Promise<void>;
  isCleaningOldChat: boolean;
}

export function ChatBotInput({
  isWaitingAIResponse,
  onNewMessage,
  isCleaningOldChat,
}: ChatBotInputProps) {
  const { userPickedPrompt } = useChatbotContext();
  const { register, handleSubmit, resetField, setFocus } = useForm<FormType>({
    shouldUseNativeValidation: true,
  });

  const onSubmit = useCallback(
    async (data: FormType) => {
      await onNewMessage(data.chatInput).then(() => {
        resetField('chatInput');
        setFocus('chatInput', { shouldSelect: true });
      });
    },
    [onNewMessage, resetField, setFocus],
  );

  useEffect(() => {
    if (!isCleaningOldChat) {
      onSubmit({ chatInput: userPickedPrompt });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPickedPrompt, isCleaningOldChat]);

  const isLoading = isWaitingAIResponse || isCleaningOldChat;

  return (
    <form
      className="bg-muted flex justify-between gap-2 px-4 py-3"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Textarea
        {...register('chatInput', {
          required: true,
        })}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }
        }}
        placeholder={
          isLoading ? userPickedPrompt : 'Ask me anything about your booking'
        }
        className="bg-background h-min flex-1 resize-none rounded-lg px-4 py-2 text-sm"
        disabled={isLoading || isCleaningOldChat}
      />
      <Button
        type="submit"
        isDisabled={isLoading || isCleaningOldChat}
        className="self-center p-5"
        variant="primary"
        size="icon"
      >
        <Icon name="sparkles" className="h-4 w-4 text-white" />
      </Button>
    </form>
  );
}
