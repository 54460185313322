import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useInViewport } from 'ahooks';
import { cva, VariantProps } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Dialog,
  Modal,
  ModalOverlay,
  ModalOverlayProps,
  OverlayTriggerStateContext,
} from 'react-aria-components';

import { Icon } from '@/components/icon';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';

import { Button } from '../button/button';
import { buttonVariants } from '../button/button-variants';

export type DrawerType = 'bottom' | 'left' | 'right' | 'modal';

// This type is used to add type checking for both
// drawerModalVariants and drawerDialogVariants
// cause these 2 variants should share the same variant
type DrawerVariant = {
  variant: Record<DrawerType, string>;
};

export const drawerModalClass = {
  animation: '!duration-200 ease-out fill-mode-forwards',
  dimensions:
    'w-full max-w-[600px] rounded-lg bg-neutral-100 px-4 py-10 lg:p-10',
};

const drawerModalVariants = cva<DrawerVariant>(
  cn('!duration-200 ease-out fill-mode-forwards', 'fixed', 'bg-white'),
  {
    variants: {
      variant: {
        bottom: cn(
          'bottom-0 left-0 right-0',
          'rounded-t-lg',
          'data-[entering]:animate-in data-[entering]:slide-in-from-bottom',
          'data-[exiting]:animate-out data-[exiting]:slide-out-to-bottom',
        ),
        left: cn(
          'left-0',
          'border-r',
          'data-[entering]:animate-in data-[entering]:slide-in-from-left',
          'data-[exiting]:animate-out data-[exiting]:slide-out-to-left',
        ),
        right: cn(
          'right-0',
          'border-l',
          'data-[entering]:animate-in data-[entering]:slide-in-from-right',
          'data-[exiting]:animate-out data-[exiting]:slide-out-to-right',
        ),
        modal: cn(
          'left-0 right-0 mx-4 max-w-[1024px] lg:mx-auto',
          'rounded-lg',
          'data-[entering]:animate-in data-[entering]:fade-in',
          'data-[exiting]:animate-out data-[exiting]:fade-out',
        ),
      },
    },
    compoundVariants: [
      {
        variant: ['left', 'right'],
        className: cn('bottom-0 top-0', 'border-neutral-400'),
      },
    ],
    defaultVariants: {
      variant: 'bottom',
    },
  },
);

const drawerDialogVariants = cva<DrawerVariant>(
  cn('flex flex-col overflow-hidden outline-none transition-all'),
  {
    variants: {
      variant: {
        bottom: '',
        left: '',
        right: '',
        modal: '',
      },
    },
    compoundVariants: [
      {
        variant: ['left', 'right'],
        className: cn('h-full', 'w-screen lg:w-[392px]', 'border-neutral-400'),
      },
      {
        variant: ['bottom', 'modal'],
        className: cn('max-h-dvh-95', 'w-full'),
      },
    ],
    defaultVariants: {
      variant: 'bottom',
    },
  },
);

const drawerHeaderVariants = cva<DrawerVariant>(
  cn('flex', 'gap-4', 'p-4 lg:p-6', 'transition-shadow duration-300'),
  {
    variants: {
      variant: {
        bottom: '',
        left: 'flex-row-reverse',
        right: '',
        modal: '',
      },
    },
    compoundVariants: [
      {
        variant: ['left', 'right'],
        className: 'justify-between items-center',
      },
      {
        variant: ['bottom', 'modal'],
        className: 'flex-col',
      },
    ],
    defaultVariants: {
      variant: 'bottom',
    },
  },
);

interface DrawerHeaderProps extends VariantProps<typeof drawerModalVariants> {
  onClose?: () => void;
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

interface DrawerHeaderCloseButtonProps {
  drawerVariant: DrawerType;
  onCloseHandler?: () => void;
  className?: string;
}

interface DrawerBodyProps {
  children: React.ReactNode;
  className?: string;
  disableScroll?: boolean;
}

interface DrawerFooterProps {
  children: React.ReactNode;
  className?: string;
}

interface DrawerProps
  extends VariantProps<typeof drawerModalVariants>,
    Omit<ModalOverlayProps, 'children'> {
  children: React.ComponentProps<typeof Dialog>['children'];
  dialogClassName?: string;
  modalClassName?: string;
  desktopVariant?: DrawerType;
}

interface DrawerContextProps {
  isScrolling: boolean;
  setIsScrolling: (isScroll: boolean) => void;
  onClose?: () => void;
  variant?: DrawerType | null;
}

export const DrawerContext = createContext<DrawerContextProps>({
  isScrolling: false,
  setIsScrolling: (isScroll: boolean) => {},
  variant: 'bottom',
});

export function DrawerHeaderCloseButton({
  drawerVariant,
  onCloseHandler,
  className,
}: DrawerHeaderCloseButtonProps) {
  let iconName: IconName;
  switch (drawerVariant) {
    case 'right': {
      iconName = 'chevron-right';
      break;
    }
    case 'left': {
      iconName = 'chevron-left';
      break;
    }
    default: {
      iconName = 'xmark';
      break;
    }
  }

  const iconComponent = <Icon name={iconName}></Icon>;

  return drawerVariant === 'bottom' || drawerVariant === 'modal' ? (
    // We have an issue with RAC Button where we click on the border
    // of the button the onPress event won't trigger so we will use
    // a regular button instead as a workaround
    // Slack discussion: https://kaligo.slack.com/archives/C04LVGR6V0X/p1693380833154159
    // Github issue: https://github.com/adobe/react-spectrum/issues/1061
    <button
      onClick={onCloseHandler}
      data-testid="drawer-close-button"
      className={cn(
        buttonVariants({
          icon: 'iconOnly',
          size: 'icon',
          variant: 'noBackground',
          noBorder: true,
        }),
        'flex-shrink-0',
        'text-[20px]',
        'self-end',
        'text-neutral-900',
        className,
      )}
    >
      {iconComponent}
    </button>
  ) : (
    <Button
      onPress={onCloseHandler}
      data-testid="drawer-close-button"
      icon="iconOnly"
      size="md"
      variant="secondary"
      className={cn('flex-shrink-0', className)}
    >
      {iconComponent}
    </Button>
  );
}

export function DrawerHeader({
  onClose,
  title,
  className,
  children,
}: DrawerHeaderProps) {
  const ctx = useContext(DrawerContext);

  const onCloseHandler = onClose ?? ctx?.onClose;

  const drawerVariant = ctx?.variant ?? 'bottom';

  return (
    <div
      className={cn(
        drawerHeaderVariants({ variant: drawerVariant }),
        {
          'shadow-elevation-medium': ctx && ctx.isScrolling,
        },
        className,
      )}
    >
      {children ?? (
        <>
          <DrawerHeaderCloseButton
            drawerVariant={drawerVariant}
            onCloseHandler={onCloseHandler}
          />
          {title ? (
            <h3
              data-testid="modal-title"
              className="text-lg-bold lg:text-3xl-bold"
            >
              {title}
            </h3>
          ) : null}
        </>
      )}
    </div>
  );
}

export function DrawerBody({
  children,
  className,
  disableScroll,
}: DrawerBodyProps) {
  const ctx = useContext(DrawerContext);
  const anchorRef = useRef<HTMLDivElement>(null);
  const parentViewportRef = useRef<HTMLDivElement>(null);

  const [isInView] = useInViewport(anchorRef, {
    root: () => parentViewportRef.current,
  });

  useEffect(() => {
    ctx?.setIsScrolling(!isInView);
  }, [isInView]);

  return (
    <div
      className={cn('flex flex-1 flex-col px-4 pt-[2px] lg:px-8', className, {
        'overflow-y-auto': !disableScroll,
      })}
      ref={parentViewportRef}
    >
      <div ref={anchorRef}></div>
      {children}
    </div>
  );
}

export function DrawerFooter({ className, children }: DrawerFooterProps) {
  const ctx = useContext(DrawerContext);

  return (
    <div
      className={cn(
        'px-4 py-6 lg:p-6',
        'transition-shadow duration-300',
        { 'shadow-elevation-medium': ctx && ctx.isScrolling },
        className,
      )}
    >
      {children}
    </div>
  );
}

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  (
    {
      children,
      variant,
      dialogClassName,
      modalClassName,
      desktopVariant,
      isDismissable = true,
      ...props
    },
    ref,
  ) => {
    const t = useTranslations('drawer');

    const drawerText = t('drawer');

    const [isScrolling, setIsScrolling] = useState(false);
    const overlayTriggerStateContext = useContext(OverlayTriggerStateContext);
    const [currentVariant, setCurrentVariant] = useState(variant);

    const isMobile = useIsMobile();

    useEffect(() => {
      if (desktopVariant) {
        setCurrentVariant(isMobile ? variant : desktopVariant);
      }
    }, [variant, desktopVariant, isMobile]);

    return (
      <DrawerContext.Provider
        value={{
          isScrolling,
          setIsScrolling,
          onClose: overlayTriggerStateContext?.close,
          variant: currentVariant,
        }}
      >
        <ModalOverlay
          {...props}
          isDismissable={isDismissable}
          className={cn(
            'z-20',
            '!duration-150 fill-mode-forwards',
            'group fixed inset-0 bg-black bg-opacity-60',
            'data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in data-[exiting]:fade-out data-[entering]:ease-out data-[exiting]:ease-in',
            // To center align the modal as doing -translate-y on Modal will cause it to appear as if it is sliding from the bottom
            currentVariant === 'modal' && 'flex items-center',
          )}
          ref={ref}
        >
          <Modal
            className={cn(
              drawerModalVariants({ variant: currentVariant }),
              modalClassName,
            )}
          >
            <Dialog
              className={cn(
                drawerDialogVariants({ variant: currentVariant }),
                dialogClassName,
              )}
              aria-label={drawerText}
            >
              {children}
            </Dialog>
          </Modal>
        </ModalOverlay>
      </DrawerContext.Provider>
    );
  },
);
Drawer.displayName = 'Drawer';

export default Drawer;
