/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/NixTqUQhBUF
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

/** Add fonts into your Next.js project:

import { Inter } from 'next/font/google'

inter({
  subsets: ['latin'],
  display: 'swap',
})

To read more about using these font, please visit the Next.js documentation:
- App Directory: https://nextjs.org/docs/app/building-your-application/optimizing/fonts
- Pages Directory: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts
**/

'use client';

import { useChatbotContext } from '@/app/chatbot';
import { ChatBotResponse, useChatBot } from '@/hooks/chat-bot/use-chat-bot';
import { useCleanChatBot } from '@/hooks/chat-bot/use-clean-chat-bot';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ChatBotDesktop } from './chat-bot-desktop';
import { ChatBotMobile } from './chat-bot-mobile';

export interface ChatDataInterface {
  id: string;
  type: 'sender' | 'receiver';
  userId: string;
  content: string;
}

export function chatMessageTransform(
  chatData: ChatBotResponse['data'],
): Array<ChatDataInterface> {
  return chatData.flatMap((message) => {
    const data: Array<ChatDataInterface> = [
      {
        id: uuidv4(),
        type: 'sender',
        userId: 'currentUser',
        content: message.userInput,
      },
      {
        id: uuidv4(),
        type: 'receiver',
        userId: 'Bot',
        content: message.assistantOutput,
      },
    ];
    return data;
  });
}

export function ChatBotAscenda() {
  const { isOpen, close, fromAirport } = useChatbotContext();
  const [chatData, setChatData] = useState<ChatDataInterface[]>([]);
  const chatBotMutation = useChatBot();
  const { mutate: cleanDataMutation, isLoading: cleaning } = useCleanChatBot();

  useMemo(() => {
    if (isOpen === true) {
      cleanDataMutation();
      setChatData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onNewMessage = useCallback(
    async (message: string) => {
      const newMessages = await chatBotMutation.mutateAsync({
        message,
        fromAirport: fromAirport?.code ?? '',
      });
      const newChatData = chatMessageTransform(newMessages.data);
      setChatData(newChatData);
    },
    [chatBotMutation, fromAirport?.code],
  );

  return (
    <div className="fixed bottom-0 right-0 top-0 z-20">
      {isOpen ? (
        <>
          <ChatBotDesktop
            chatData={chatData}
            isWaitingAIResponse={chatBotMutation.isLoading}
            onNewMessage={onNewMessage}
            cleaning={cleaning}
            close={close}
            className="hidden lg:block"
          />
          <ChatBotMobile className="lg:hidden" />
        </>
      ) : null}
    </div>
  );
}
