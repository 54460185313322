import { Button } from '@/design-system-components/button/button';
import { Icon } from '../icon';

export function ChatBotHeader({ onClose }: { onClose: () => void }) {
  return (
    <div className="flex h-[72px] items-center justify-between bg-primary px-4">
      <div className="flex items-center gap-2">
        <div className="font-medium text-lg text-white">Your AI concierge</div>
      </div>
      <Button size="icon" onPress={onClose} className="bg-transparent">
        <Icon name="xmark" className="h-5 w-5 text-white" />
        <span className="sr-only text-white">Close</span>
      </Button>
    </div>
  );
}
