'use client';

import { useTranslations } from 'next-intl';
import { useContext } from 'react';

import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { Button } from '@/design-system-components/button/button';
import Drawer, {
  DrawerBody,
  DrawerHeader,
} from '@/design-system-components/drawer/drawer';
import { useTenantName } from '@/hooks/utils/use-tenant-name';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { cn } from '@/utils/tailwind';

const CARLTON_ONE_STOREFRONT_URL = '/auth/apple';

export default function RedirectModal() {
  const tenantName = useTenantName();
  const t = useTranslations('redirectModal');
  const { setShowRedirectModal, showRedirectModal } =
    useContext(RedirectModalContext);

  const aboutToLeaveText = tenantName
    ? t('aboutToLeave_tenantName', { tenantName })
    : '';
  const redirectText = t('redirect');
  const stayHereText = t('stayHere');
  const proceedText = t('proceed');

  return (
    <Drawer
      isOpen={showRedirectModal}
      desktopVariant="modal"
      className="flex items-center"
      data-testid="redirect-modal-dialog"
      modalClassName={cn(
        '!duration-500 ease-out fill-mode-forwards',
        'rounded bg-white lg:w-[600px] lg:self-center lg:justify-self-center',
      )}
    >
      {({ close }) => {
        return (
          <>
            <DrawerHeader
              title={aboutToLeaveText}
              className="gap-1 lg:gap-0"
              onClose={() => {
                setShowRedirectModal(false);
                close();
              }}
            />
            <DrawerBody className="lg:pb-6">
              <p className="text-body">{redirectText}</p>
              <div className="my-6 flex flex-grow flex-col-reverse justify-end gap-4 lg:mb-0 lg:mt-8 lg:flex-row lg:gap-6">
                <Button
                  className="lg:block"
                  variant="secondary"
                  fullWidth="mobile"
                  data-testid="redirect-modal-close-button"
                  onPress={() => {
                    setShowRedirectModal(false);
                    close();
                  }}
                >
                  {stayHereText}
                </Button>
                {/* this submit action needs to be a link because we are redirecting to an external site on a new tab */}
                <RcLink
                  variant={RcLinkVariant.BUTTON}
                  className="lg:block"
                  data-testid="redirect-modal-proceed-button"
                  target="_blank"
                  href={CARLTON_ONE_STOREFRONT_URL}
                  rel="noopener noreferrer"
                  prefetch={false}
                >
                  {proceedText}
                </RcLink>
              </div>
            </DrawerBody>
          </>
        );
      }}
    </Drawer>
  );
}
