import { ChatDataInterface } from './chat-bot';
import { ChatBotBody } from './chat-bot-body';
import { ChatBotHeader } from './chat-bot-header';
import { ChatBotInput } from './chat-bot-input';

interface ChatBotDesktopProps {
  chatData: ChatDataInterface[];
  isWaitingAIResponse: boolean;
  onNewMessage: (message: string) => Promise<void>;
  cleaning: boolean;
  close: () => void;
  className?: string;
}

export function ChatBotDesktop({
  chatData,
  isWaitingAIResponse,
  onNewMessage,
  cleaning,
  close,
  className,
}: ChatBotDesktopProps) {
  return (
    <div className="mb-[10px] flex h-full w-[400px] max-w-md flex-col overflow-hidden bg-white shadow-elevation-medium">
      <ChatBotHeader onClose={close} />
      <ChatBotBody
        chatData={chatData}
        isWaitingAIResponse={isWaitingAIResponse}
      />
      <div className="border-t border-t-neutral" />
      <ChatBotInput
        onNewMessage={onNewMessage}
        isWaitingAIResponse={isWaitingAIResponse}
        isCleaningOldChat={cleaning}
      />
    </div>
  );
}
