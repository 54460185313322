'use client';

import { AppEnv } from '@/store/store';
import { init } from '@amplitude/analytics-browser';
import { useEffect } from 'react';

const enabledEnvs = new Set<AppEnv>(['staging']);

interface AmplitudeProviderProps {
  appEnv: AppEnv;
}

function initAmplitude(appEnv: AppEnv) {
  const AMPLITUDE_ID =
    appEnv === 'production'
      ? '<TO_BE_UPDATED>'
      : 'f8d781d4545205ee9452129f64ec3c5a';

  if (!enabledEnvs.has(appEnv)) {
    return;
  }
  init(AMPLITUDE_ID);
}

export function AmplitudeProvider({ appEnv }: AmplitudeProviderProps) {
  useEffect(() => {
    initAmplitude(appEnv);
  }, []);
  return null;
}
