import * as amplitude from '@amplitude/analytics-browser';
import { useEffect } from 'react';

export const useAmplitudeTenantReady = (
  tenantId: string | undefined,
  userId: string | undefined,
) => {
  useEffect(() => {
    if (!userId || !tenantId) return;

    const identifyEvent = new amplitude.Identify();
    identifyEvent.setOnce('tenantId', tenantId);
    identifyEvent.setOnce('userId', userId);
    amplitude.identify(identifyEvent);
  }, [tenantId, userId]);
};
