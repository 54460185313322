import { IconStyle } from '@/config-schema/rewards-central-config'; 
export const fontawesomeIconMap: Map<IconStyle, string> = new Map(); 
fontawesomeIconMap.set('duotone', '/assets/icon/sprites/output/duotone.7023db.svg'); 
fontawesomeIconMap.set('thin', '/assets/icon/sprites/output/thin.7ae43f.svg'); 
fontawesomeIconMap.set('light', '/assets/icon/sprites/output/light.6822a8.svg'); 
fontawesomeIconMap.set('regular', '/assets/icon/sprites/output/regular.d92513.svg'); 
fontawesomeIconMap.set('solid', '/assets/icon/sprites/output/solid.9bbd2a.svg'); 
fontawesomeIconMap.set('sharp-duotone-solid', '/assets/icon/sprites/output/sharp-duotone-solid.42f1fe.svg'); 
fontawesomeIconMap.set('sharp-light', '/assets/icon/sprites/output/sharp-light.4fc583.svg'); 
fontawesomeIconMap.set('sharp-regular', '/assets/icon/sprites/output/sharp-regular.8e3263.svg'); 
fontawesomeIconMap.set('sharp-solid', '/assets/icon/sprites/output/sharp-solid.20de78.svg'); 
fontawesomeIconMap.set('sharp-thin', '/assets/icon/sprites/output/sharp-thin.94bd7d.svg')