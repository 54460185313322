import { cn } from '@/utils/tailwind';
import { useEffect, useRef } from 'react';
import { Icon } from '../icon';
import { ChatDataInterface } from './chat-bot';
import { ChatBotMessage } from './chat-bot-message';

export function ChatBotBody({
  chatData,
  isWaitingAIResponse,
}: {
  chatData: ChatDataInterface[];
  isWaitingAIResponse: boolean;
}) {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatData, isWaitingAIResponse]);

  return (
    <div ref={scrollRef} className="flex-1 space-y-4 overflow-y-auto p-4">
      {chatData.map((dialog) => {
        const isSender = dialog.type === 'sender';
        return (
          <div
            key={dialog.id}
            className={cn('flex', {
              'flex-row-reverse': isSender,
            })}
          >
            {isSender ? null : (
              <div className="h-8 w-8 pt-3">
                <Icon name="robot" className="h-8 w-8" />
              </div>
            )}
            <div
              className={cn('bg-muted max-w-[75%] rounded-lg p-3', {
                'bg-primary-200': isSender,
              })}
            >
              {isSender ? (
                <div>{dialog.content}</div>
              ) : (
                <ChatBotMessage message={dialog.content} />
              )}
            </div>
          </div>
        );
      })}

      {isWaitingAIResponse && (
        <div className="flex justify-center">
          <div className="h-8 w-8 animate-spin">
            <Icon name="loader" className="h-8 w-8" />
          </div>
        </div>
      )}
    </div>
  );
}
